import logoUrl from '../img/home/SIGNATURE_LTO.png'
import outdoorLogo from '../img/outdoor/SIGNATURE_LTO.png';
import privateLogo from '../img/home/hovered_images/private/SIGNATURE_LTO.png';
import corporatelogo from '../img/home/hovered_images/corp/SIGNATURE_LTO.png';

const home_outdoor = document.querySelector('.home_outdoor');
const home_private = document.querySelector('.home_private');
const home_corpo = document.querySelector('.home_corpo');
const home_pageSection = document.querySelectorAll('.home_pageSection');
const logo = document.getElementById('logo');

const changeLogoOnHover = function(path) {
  logo.src = path;
}

const resetlogo = () => logo.src = logoUrl;

home_outdoor.addEventListener('mouseover', () => changeLogoOnHover(outdoorLogo));
home_private.addEventListener('mouseover', () => changeLogoOnHover(privateLogo));
home_corpo.addEventListener('mouseover', () => changeLogoOnHover(corporatelogo));
home_pageSection.forEach(pageSection => pageSection.addEventListener('mouseleave', resetlogo));
